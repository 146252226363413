import styled from "styled-components";
import { THEME } from "../../../Theme";

export const TalkWrapper = styled.div`
    background:white;
    height:100vh;
    width:100%;
    display:flex;
    position:relative;
    flex-direction:column;
    
    @media ${THEME.LAPTOP} {
        flex-direction:row;
        
    }
`

export const TalkNavLogo = styled.div`
    position:absolute;
    top:2%;
    left:20px;
    z-index:10;

    @media ${THEME.TABLET} {
        left:100px;
    }

    & img{
        max-width:150px;

        @media ${THEME.TABLET} {
            max-width:200px;
        }
    }
    `

export const TalkNavToggleBtn = styled.div`
    position:absolute;
    top:0;
    right:50px;
    width:60px;
    height:60px;
    cursor: pointer;
    z-index:10;

    @media ${THEME.TABLET} {
        top:2%;
    }

    & img{
        max-width:60px
    }

    & div{
    width:60px;
    height:60px;
    padding:15px;
    /* background:red; */
    /* z-index: ${THEME.z_100}; */
    display: flex;
    justify-content: center;
    align-items:center;
    transition: .5s;
    cursor: pointer;

    &:hover span{
        background:#fff;
    }

     & span{
        position:absolute;
        height:2px;
        width:30px;
        background:${props => props.hold ? 'white' : 'black'};
        border-radius:4px;
        transition:0.5s;
        
        @media ${THEME.TABLET}
        {
            background:${props => props.hold ? 'black' : 'black'};
        }

        &:first-child { /* Use :first-child instead of :nth-child(1) */
            transform: translateY(-5px);
        }

        &:last-child { /* Use :first-child instead of :nth-child(1) */
            transform: translateY(5px);
        }
    }

    }
`


export const ListWrapper = styled.div`  // ----------  List Left Side
    background:#fff;
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    position:relative;

    @media ${THEME.LAPTOP} {
        width:calc(100% - 456px);
    }

    &  div.progress{
        position:absolute;
        content:'';
        width:320px;
        height:6px;
        background:rgba(0,0,0,0.23);
        bottom:8%;
        left:50%;
        border-radius:6px;
        transform:translateX(-50%);

        @media ${THEME.TABLET} {
            width:360px;
        }

        &::after{
        position:absolute;
        content:'';
        /* width:100%; */
        height:6px;
        background:#0362D7;
        transition:0.5s;
        /* bottom:8%; */
        left: 0;
        width:${props => props.percentage}%;
        border-radius:6px;
    }
    }
`

export const ContactWrapper = styled.div`  //  -------- > Contact Right Side
    background: #F9FCFF;
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;

    @media ${THEME.LAPTOP} {
        width:456px;
    }
`

export const ContactHolder = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    padding:40px;
    gap:20px;

    @media ${THEME.TABLET} {
        flex-direction:row;
    }

    @media ${THEME.LAPTOP} {
        flex-direction:column;
        width:456px;
        gap:40px;
    }
`

export const ContactCard = styled.div`
    width:100%;

    & h2{
        color: #212529;
    font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 31px; /* 129.167% */
        letter-spacing: 0.12px;
    }

   & .Holder{
        display:flex;
        flex-direction:column;

        @media ${THEME.TABLET} {
            flex-direction:row;
        }

        @media ${THEME.LAPTOP} {
            flex-direction:column;
        }
    }

        & .holder{
            width:36px;
            & img{
                max-width:36px;
            }
        }
        & .content{
            flex:1;
            margin-top:20px;
            padding-left:10px;
            width:50%;

            @media ${THEME.TABLET} {
        width:100%;
    }
            h3{
                color: rgba(0, 0, 0, 0.80);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 36px; /* 200% */
                letter-spacing: 0.175px;
            }

            h6{
                color: rgba(0, 0, 0, 0.30);
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px; /* 200% */
                letter-spacing: 0.175px;
            }
            a{
                color: #000;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 25px; /* 156.25% */
                text-decoration-line: underline;
            }
    }
`

export const ListCard = styled.div`    // --------> Requirements
    width:100%;
    margin:auto;
    display:flex;
    flex-direction:column;
    gap:20px;
    padding:80px 40px;

    @media ${THEME.LAPTOP} {
        width:80%;
        padding:0;
        gap:40px;
    }

    & h1{
            color: #212529;
    font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px; /* 120.802% */
            letter-spacing: 0.283px;
            max-width:550px;

            @media ${THEME.TABLET} {
                font-size: 40px;
                line-height: 68.364px; /* 120.802% */
            }

            @media ${THEME.LAPTOPL} {
                font-size: 56.592px;
                line-height: 68.364px; /* 120.802% */
            }
        }

        & div.req{
            display:flex;
            flex-wrap:wrap;
            gap:10px;
            cursor: pointer;

            @media ${THEME.TABLET} {
                gap:20px;
            }

            span{
                display: inline-flex;
                padding: 15px 20px;
                align-items: flex-start;
                border-radius: 100px;
                border: 1px solid #1A1A1A;
                color: #000;
    font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                letter-spacing: 0.1px;
                transition: background-image 0.5s, color 0.4s ;

            @media ${THEME.LAPTOPL} {
                padding: 21px 41px;
                line-height: 32px;
                font-size: 20px;
            }

                &:hover, &.active{
                    background-image: linear-gradient(117deg, #F00 -130.82%, #FAFF00 170.1%);
                    border: 1px solid transparent;
                    color:#fff;
                }
            }
        }
`

export const Controls = styled.div`
 display:flex;
            gap:26px;
            padding-left:20px;
            flex-direction:column;

            @media ${THEME.TABLET} {
                flex-direction:row;
            }

            span.btn{
                width: 53px;
                height: 30px;
                border-radius: 6px;
                background: #111;
                color: #FFF;
                font-family: 'Raleway', sans-serif;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 25px; /* 156.25% */
                letter-spacing: 0.08px;
                cursor: pointer;
            }
            div{
                color: #000;
                font-family: 'Raleway', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 25px; /* 156.25% */
                letter-spacing: 0.08px;
                display: flex;
                gap: 10px;
                align-items: center;
                
                span.text{
                    font-weight: 600;
                }
            }
`

export const DescriptionWrapper = styled.div` // ------- > Project Description
    width:100%;
    margin:auto;
    display:flex;
    flex-direction:column;
    gap:20px;
    padding:80px 40px;

    @media ${THEME.LAPTOP} {
        width:80%;
        padding:0;
        gap:40px;
    }

    & h1{
        color: #212529;
    font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 100% */
        letter-spacing: 0.16px;

        @media ${THEME.TABLET} {
            font-size: 32px;
        }
    }
`

export const InfoMark = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-start;
    gap:10px;
    & span{
        color: #000;
    font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 156.25% */
        letter-spacing: 0.08px;
        &.text{        
            font-weight: 600;
        }
        @media ${THEME.MOBILEL} {
            font-size: 16px;
        }
    }
`

export const TimeNeedWrapper = styled.div`// ------- > Project TimeNeeds
    width:100%;
    margin:auto;
    display:flex;
    flex-direction:column;
    gap:20px;
    padding:80px 40px;

    @media ${THEME.LAPTOP} {
        width:80%;
        padding:0;
        gap:40px;
    }

    & h1{
        color: #212529;
    font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 100% */
        letter-spacing: 0.16px;

        @media ${THEME.TABLET} {
            font-size: 32px;
        }
    }
        & div.req{
            display:flex;
            flex-wrap:wrap;
            gap:10px;
            cursor: pointer;

            @media ${THEME.TABLET} {
                gap:20px;
            }

            span{
                display: inline-flex;
                padding: 15px 20px;
                align-items: flex-start;
                border-radius: 100px;
                border: 1px solid #1A1A1A;
                color: #000;
    font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;
                letter-spacing: 0.1px;
                transition:0.5s;

            @media ${THEME.LAPTOPL} {
                padding: 21px 41px;
                line-height: 32px;
                font-size: 20px;
            }

                &:hover, &.active{
                    background: linear-gradient(117deg, #F00 -130.82%, #FAFF00 170.1%);
                    border:none;
                    color:#fff;
                }
            }
        }
    `

export const AddressWrapper = styled.div`
    width:100%;
    margin:auto;
    display:flex;
    flex-direction:column;
    gap:20px;
    padding:90px 40px;

    @media ${THEME.LAPTOP} {
        width:80%;
        padding:0;
        gap:40px;
    }

    & h1{
        color: #212529;
    font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 100% */
        letter-spacing: 0.16px;

        @media ${THEME.TABLET} {
            font-size: 32px;
        }
    }
    button{
        display: inline-block;
        width:max-content;
        padding: 15px 20px;
        align-items: flex-start;
        border-radius: 100px;
        border: 1px solid #1A1A1A;
        color: #000;
        background:#fff;
    font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 160% */
        letter-spacing: 0.1px;
        cursor: pointer;
        @media ${THEME.TABLET} {
            padding: 21px 40px;
            font-size: 20px;
        }
    }
`

export const CustomInput1 = styled.input`
    width:100%;
    display: inline-flex;
    padding: 0px 2px 10px 2px;
    justify-content: center;
    align-items: center;
    border:none;
    outline:none;
    border-bottom: 2px solid rgba(0,0,0,.3);
    background: #FFF;
    font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.16px;
        color: #000;

        @media ${THEME.TABLET} {
        font-size: 20px;
    }

    &::placeholder{
        color: #A1A1A1;
        font-weight: 400;
    }

  &:focus{
    box-shadow:none;
    border-color:rgba(0, 0, 0, 0.76);
  }


`

export const ModalContent =  styled.div`
    position: relative;
    margin-bottom: 50px;

    & h2{
        color: #000;
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        text-align:center;
        font-style: normal;
        font-weight: 600;
        line-height: 35px; /* 145.833% */
    }
`

