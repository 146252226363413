import styled from 'styled-components'
import { THEME } from '../Theme'

export const Container_fluid = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  /* padding: 0 10px; */
`


export const Container = styled.div`
  width: 100%;
  margin: 15px auto;
  padding-right: calc(${THEME.gutter_x} * 0.5);
  padding-left: calc(${THEME.gutter_x} * 0.5);
  position:relative;

  /* @media ${THEME.MOBILEL} {
    max-width: 560px;
  } */

  @media ${THEME.TABLET} {
      width: 90%; // Set a default width for the container
    margin: 0 auto;
  }
  @media ${THEME.LAPTOPL} {
    width: 80%;
  }

 
/*  @media ${THEME.LAPTOPL} {
    max-width: 1180px;
  }

  @media ${THEME.DESKTOP} {
    max-width: 1380px;
  }

  @media ${THEME.DESKTOPL} {
    max-width: 1780px;
  } */
`