import styled from "styled-components";
import { THEME } from "../../Theme";

export const Container = styled.section`
    height:calc(100vh - 80px);
    background:#fff;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:20px;
    flex-direction:column;

    @media screen and (min-width:1010px){
        height:calc(100vh - 115px);
    }

     @media screen and (min-width:820px){
        height:calc(100vh - 136px);
    }

    & img{
        max-width:100%;
        width: 400px;
    }
& .holder{
    display:flex;
    align-items:center;
    justify-content:center;
    gap:20px;
    flex-direction:column;

    h1 {  
    color: #e7b826;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    padding: 6.5px 5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    text-align:center;
    }

    h2 {
    color: #000;
    text-align:center;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    padding: 6.5px 5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;

    }
}
`