import styled from "styled-components";
import { THEME } from "../../../Theme";

export const ReviewSectionHolder = styled.section`
  background:#101010;
  height:100vh;
  display:flex;
  align-items:center;
  justify-content:center;
`

export const ResTestimonialCardWrapper = styled.div`
  display:block;
  height:max-content;
  width: 100%;
  margin: auto;
  @media ${THEME.MOBILEL} {
    width:80%;
  }

  @media ${THEME.TABLET} {
    display:none;
  }
`
export const ResTestimonialCard = styled.div`   
  display: flex;
  width: 100%;
  height: max-content;
  flex-direction: column;
  padding: 5vw 5vw 1vw;

  & .testimonial_carousel_logo_area {
    display: flex;
    width: 100%;
    height: max-content;
    justify-content: center;
    img{
      width:35%;
    }
  }

  & .testimonial_carousel_description {
      display: flex;
      width: 100%;
      justify-content: center;
      height: max-content;
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: .01em;
      text-align: center;
      color: #fff;
      margin-top: 30px;
  }

  & .testimonial_carousel_client_area {
    display: flex;
    width: 100%;
    height: max-content;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    flex-direction: column;

    .testimonial_carousel_client_img_holder{
      overflow: hidden;
      width: 200px !important;
      height: 200px !important;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
      display: flex!important;
      width: 100% !important;
      /* height: 200px !important; */
      /* border-radius: 1000px; */
      overflow: hidden;

      }
    }

      .testimonial_carousel_client_text {
        display: flex;
        width: max-content;
        height: max-content;
        margin-left: 20px;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;

      .testimonial_carousel_client_text_name {
          display: flex;
    font-family: 'Poppins', sans-serif;
          font-size: 28px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: center;
          color: #fff;
        }

        .testimonial_carousel_client_text_desgination {
          display: flex;
    font-family: 'Poppins', sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 31px;
          letter-spacing: 0em;
          text-align: center;
          margin-top: 6px;
          color: #fff;
        }

        .testimonial_carousel_client_text_linkedin{
          display: flex;
          width: max-content;
          height: max-content;
          gap:10px;
          margin-top: 8px;
          font-family: 'Raleway', sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.05em;
          text-align: left;
          color: #1b7fdc;
          cursor: pointer;
          align-items: flex-end;
          
          span{
            text-decoration: underline;
          }
          h1{
            color:#fff;
          }
        }
      }
    }
`

export const TestimonialCardWrapper = styled.div`
  display:none;

  @media ${THEME.TABLET} {
    display: flex;
    width: 90%;
    height: max-content;
    flex-direction: column;
    /* padding-top: 15vh; */
    min-height: 50vh;
    align-items: center;
    position: relative;
    margin:auto;
  }
`

export const TestimonialCardHolder = styled.div`
    display: flex;
    width: 100%;
    height: max-content;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    justify-content: center;

    & .testimonial_section_content_main_image_container {
      display: flex;
      /* height: 35vh; */
      align-self: flex-start;
      flex-direction: column;
      overflow:hidden;

      a{
        text-decoration:none;
      }
    }

    & .testimonial_section_content_main_text {
      display: flex;
      width: max-content;
      height: max-content;
      min-height: 40vh;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 6vw;

      .testimonial_section_content_main_text_logo_area {  // 1st div
        display: flex;
        width: max-content;
        height: max-content;
        align-items: flex-end;

        .testimonial_section_content_main_text_logo_image_container {
          display: flex;
          width: 7vw;
          height: auto;
          overflow: hidden;
          align-items: flex-end;
          padding-top: 1vh;

          img{
            display: flex;
            width: 100%;
            transform: translate(0px, 110%);
            transition: 0.5s linear;
            transition-delay: 0.1s;

            &.active{
              transform: translate(0px, 0px);
              transition-delay:0.8s;
              z-index:10;
            }
          }
        }
      }

      .testimonial_section_content_main_text_title {  // 2nd div
        display: flex;
        font-size: 42px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: left;
        align-items: flex-end;
        color: #fff;
        width: 100%;
        overflow: hidden;
        gap:20px;
        margin-top: 5vh;

        span.des{
          color: #FFF;
          font-family: 'Poppins', sans-serif;
          font-size:16px;
          font-style: normal;
          font-weight: 400;
          line-height: 31px; /* 140.909% */
          transform: translate(0px, 100%);
            transition: 0.5s linear;
            transition-delay: 0.3s;

            @media ${THEME.LAPTOP} {
              font-size: 22px;
            }
            &.active{
              transform: translate(0px, 0px);
              transition-delay:1.2s;
              z-index:10;
            }
        }
      }

      .testimonial_section_content_main_text_description {
        display: flex;
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: .0125em;
        text-align: left;
        color: #a1a1a1;
        margin-top: 2vh;
        width: 26vw;
        flex-wrap: wrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .testimonial_content_description_div_container {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          height: max-content;
          gap:10px;

          .testimonial_content_description_div {
            display: flex;
            width: max-content;
            height: max-content;
            overflow: hidden;
          }
        }
      }

    }
`
export const TestimonialClientImgHolder = styled.div`
        width: 291px;
        aspect-ratio:1;
        overflow:hidden;
        position:relative;
        /* background:red; */
        margin:auto;
        img{
          position:absolute;
          /* ${props => props.order === 1 ? 'left: 100%' : 'left:-100%' }; */
          max-width:100%;
          transition: 2s;
          left:-100%;
          top:50%;
          transform:translateY(-50%);
          opacity:0;
          z-index:2;
          transition-delay: 0.1s;
          /* transform:scale(0); */
          /* perspective:600px; */

          &.active{
            left:0;
            /* transform:scale(1); */
            opacity:1;
            transition-delay: ${props => props.count * 0.4}s;
            z-index:5;
          }
        }     
`

export const TestimonialLinkedIn = styled.div`
  display: flex;
  width: max-content;
  height: max-content;
  margin-top: 12px;
  margin-left: 12px;
  color: #1b7fdc;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  align-items: flex-end;
  justify-content:center;
  gap:10px;
  cursor: pointer;
  position: relative;
  visibility:hidden;
  opacity:0;
  transition:0.5s;

  &.active{
    visibility:visible;
    opacity:1;
  }
  h1{
    color:#fff;
    font-size: 26px;
  }
`

export const TestimonialContentTitle = styled.span`
          color: #FFF;
          font-family: 'Poppins', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 45.36px; /* 127.273% */
          transform: translate(0px, 100%);
          transition: 0.5s linear;
          transition-delay: 0.2s;

          @media ${THEME.LAPTOP} {
            font-size: 35.64px;
          }
          &.active{
            transform: translate(0px, 0px);
            transition-delay:1s;
            z-index:10;
          }
`

export const TestimonialContentDescriptionSpan = styled.span`
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.225px;
    transform: translate(0px, 100%);
    transition: .5s;
    transition-delay: 0.3s;


    @media ${THEME.LAPTOP} {
      font-size: 18px;
    }
    
    &.active{
      /* transition-delay: ${props => props.count * 0.1}s; */
      transition-delay: 1.4s ;
      transform: translate(0px, 0px);
      z-index:10;
    }
`


export const ReviewCardWrapper = styled.div`
  height: 100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  text-align:center;
  @media ${THEME.TABLET} {
    text-align:left;
  }
`



export const ReviewCard = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & img{
    max-width:300px;
  }
`;

export const ReviewBlack = styled.div`
  background: #101010;
  /* height: 800px; */
  /* padding: 200px 0px ; */

  
  @media (max-width: 768px) {
  padding: 55px 0px;

   
  }
`;

export const Brand = styled.img`
  width: 205px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReviewName = styled.p`
  color: #fff;
    font-family: 'Poppins', sans-serif;
  font-size: 35.64px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.36px;

  & span {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const ReviewContent = styled.p`
  margin-top: 10px;
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.225px;
`;