import Dev1 from './Devops/ansible.svg'
import Dev2 from './Devops/docker.svg'
import Dev3 from './Devops/jenkins.svg'
import Dev4 from './Devops/kubernetes.svg'
import Dev5 from './Devops/nginx.svg'
import Dev6 from './Devops/aws.svg'

import Test1 from './Testing/apachejmeter.svg'
import Test2 from './Testing/appium.svg'
import Test3 from './Testing/postman.svg'
import Test4 from './Testing/selenium.svg'

import Marketing1 from './Marketing/facebook.svg'
import Marketing2 from './Marketing/instagram.svg'
import Marketing3 from './Marketing/linkedin.svg'
import Marketing4 from './Marketing/whatsapp.svg'

import Web1 from './Web/html.svg'
import Web2 from './Web/css.svg'
import Web3 from './Web/bootstrap.svg'
import Web4 from './Web/laravel.svg'
import Web5 from './Web/react.svg'

import Mobile1 from './Mobile/react.svg'

import App1 from './Software/django.svg'
import App2 from './Software/java.svg'
import App3 from './Software/laravel.svg'
import App4 from './Software/react.svg'
import App5 from './Software/vite.svg'

export const softwareLogo = {
    Dev1,  // Devops
    Dev2, 
    Dev3,
    Dev4,
    Dev5,
    Dev6,

    Test1,  // Testing
    Test2,
    Test3,
    Test4,

    Marketing1,  // Marketing
    Marketing2,
    Marketing3,
    Marketing4,

    Web1,  //  Web
    Web2,
    Web3,
    Web4,
    Web5,

    Mobile1,  //  Mobile

    App1,  // Customized Software
    App2,
    App3,
    App4,
    App5,
}