import styled from "styled-components"
import { THEME } from "../../../Theme"



export const FirstSections = styled.div`

`

export const FirstSection = styled.div`
margin-top: 90px;

@media (max-width: 768px) {
margin-top: 5px;


  }

`

export const ServiceBlack = styled.div`
background-color: black;
height:600px;

.servicecontent{

font-family: 'Inter', sans-serif;
font-size: 15.047px;
font-style: normal;
font-weight: 700;
line-height: 26.46px; 
letter-spacing: 0.9px;
}


.service{
padding: 100px 40px 40px 0px;
display: flex;
justify-content: left;
align-items: center;
font-family: 'Raleway', sans-serif;
font-size: 66.5px;
font-style: normal;
font-weight: 700;
line-height: 119px; 
letter-spacing: 0.76px;

background: linear-gradient(108deg, #F00 3.16%, #FFE500 62.52%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}


`

export const ResearchDesign = styled.div`
/* padding: 270px; */
padding: 180px 40px 40px 0px;

@media (max-width: 768px) {
padding: 10px;

  }



& p {

color: #000;
text-align: right;
font-family: 'Inter', sans-serif;
font-size: 29.25px;
font-style: normal;
font-weight: 200;
line-height: 42px;
letter-spacing: 0.32px;

@media (max-width: 768px) {
 display: flex;
 justify-content: center;
 align-items: center;

  }

}

`

export const FirstCol = styled.div`
padding: 85px;
background:red;
& p {

color: #000;
font-family: 'Raleway', sans-serif;
font-size: 35.313px;
font-style: normal;
font-weight: 600;
letter-spacing: 0.4px;

 @media (max-width: 768px) {
/* padding: 35px; */
  } 

}

`

export const SecCol = styled.div`
padding: 85px;

@media (max-width:768px) {
  padding: 20px;
  
}


& p {

color: #000;
font-family: 'Raleway', sans-serif;
font-size: 35.313px;
font-style: normal;
font-weight: 600;
letter-spacing: 0.4px;
}


`

export const FirstColRight = styled.div`
padding: 50px;
.research{

color: #000;
font-family: 'Raleway', sans-serif;
font-size: 36.094px;
font-style: normal;
font-weight: 300;
line-height: 59px; /* 163.463% */
letter-spacing: 0.4px;

}

.researchcontent{
color: #000;
font-family: 'Inter', sans-serif;
font-size: 15.047px;
font-style: normal;
font-weight: 300;
line-height: 26.46px; /* 175.85% */
letter-spacing: 0.9px;
}

li{
padding: 15px;
color: #000;
font-family: 'Inter', sans-serif;
font-size: 15.188px;
font-style: normal;
font-weight: 300;
line-height: 26.46px; /* 174.222% */
letter-spacing: 0.9px;
}

img{
padding: 15px;
   
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

`

export const Secondsection = styled.div`

`

export const OverviewImg = styled.div`

& img {
    max-width: 50%;


}

`

// -----------  Service 

export const ServiceWrapper = styled.section`
  height:100vh;
  position:relative;
  display:flex;
  flex-direction:column;

  @media ${THEME.TABLET} {
    flex-direction:row;
  }

  & .leftWrapper{
    width:100%;
    background:#000;
    padding: 2rem;
    height:50vh;
    display:flex;
    align-items:flex-start;
    justify-content:center;
    flex-direction:column;
    gap:30px;

    @media ${THEME.TABLET} {
      height:100%;
      width:50%;
    }

    h2{
      text-align: left;
      font-family: 'Raleway', sans-serif;
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px; /* 178.947% */
      letter-spacing: 0.76px;
      /* margin-bottom:20px; */

      @media ${THEME.TABLET} {
        font-size: 66.5px;
        line-height: 119px; /* 178.947% */
        margin-bottom:20px;
      }
    }

    p{
      color: #FFF;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 175.85% */
      letter-spacing: 0.9px;

      @media ${THEME.TABLET} {
        font-size: 15.047px;
        line-height: 26.46px; /* 175.85% */
      }
    }
  }

  & .rightWrapper{
    width:100%;
    padding: 2rem;
    height:50vh;
    display:flex;
    align-items:center;
    justify-content:center;
    position:relative;
    
    @media ${THEME.TABLET} {
      height:100%;
      width:50%;
    }

    & .arrow{
        position:absolute;
        bottom:20px;
        right:20px;
      }
    
    & .listholder{
      position:relative;
      width:max-content;
      padding:0 30px;
      display:flex;
      gap:10px;
      flex-direction:column;

      @media ${THEME.TABLET} {
        gap:18px;
      }

    & .placeleft{
      position:absolute;
      left:-20px;
      top:20%;
      color: #000;
      text-align: center;
      font-family: 'Inter', sans-serif;
      font-size: 15.059px;
      font-style: normal;
      font-weight: 300;
      line-height: 22.5px; /* 149.409% */
      letter-spacing: 0.158px;
      text-transform: uppercase;
      transform:rotate(-90deg) translateY(-50%); 
      &::before{
        position:absolute;
        width:60px;
        height:2px;
        background: #1416FF;
        content:'';
        top:50%;
        left:-62px;
        transform:translateY(-50%);
      }
    }

      h2{
        color: #000;
        text-align: right;
        font-family: 'Inter', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 200;
        line-height: 36px; /* 143.59% */
        letter-spacing: 0.32px;

        @media ${THEME.TABLET} {
          font-size: 29.25px;
          line-height: 42px; /* 143.59% */
        }
      }
    }
  }
`

export const ServiceContentWrapper = styled.section`
  display:flex;
  gap:50px;
  flex-direction:column;
  margin:50px 0;
`

export const ServiceContentHolder = styled.div`
  display:flex;
  align-items:flex-start;
  justify-content:center;
  gap:20px;
  flex-direction:column;

  @media ${THEME.TABLET} {
    flex-direction:row;
  }
`

export const ServiceRowHolder = styled.div`
  display:flex;
  gap: 50px;
  flex-direction: column;
  /* width:80%; 
  margin: auto; */
  height:min-content;

  @media ${THEME.TABLET} {
    width:50%; 
    margin: 0;
    height:100%;
  }
`

export const ServiceRowContentRow = styled.div`
  width:90%;
  margin:auto;
`

export const ServiceContentRow = styled.div`
  width:80%; 
  margin: auto;
  height:min-content;

  @media ${THEME.TABLET} {
    width:50%; 
    margin: 0;
    height:100%;
  }
`

export const ServiceTitle = styled.div`
  display:none;
   @media ${THEME.TABLET} {
    display:block;
   }

  & h2{
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 59px; /* 167.08% */
    font-size: 35.313px;
    letter-spacing: 0.4px;

    span{
      /* background:red; */
      display:block;
      opacity: 0;
      transition:0.5s;
      transform: translateY(1em);
    animation: fadeInUp 1s forwards;
    }

        @keyframes fadeInUp {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &.active span:nth-child(2){
      display:none;
    }
    &.active1 span:nth-child(1){
      display:block;
    }
    &.active1 span:nth-child(2){
      display:none;
    }
    &.active2 span:nth-child(1){
      display:none;
    }
    &.active2 span:nth-child(2){
      display:block;
    }
}
`

export const ResServiceTitle = styled.div`
  display:block;
  margin-bottom:50px;
    @media ${THEME.TABLET} {
      display:none;
    }

& h2{
  color: #000;
  font-family: 'Raleway', sans-serif;
  font-size: 25px;
  font-style: normal;
  background:#fff;
  font-weight: 600;
  line-height: 40px; /* 167.08% */
  letter-spacing: 0.4px;
}
`


export const ServiceCardHolder = styled.div`
  display:flex;
  flex-direction:column;
  gap:50px;
  /* margin-top: 50px; */
`
export const ServiceCard = styled.div`
  & h2{
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px; /* 163.463% */
    letter-spacing: 0.4px;
    margin-bottom:20px; 
    @media ${THEME.TABLET} {
      line-height: 59px; /* 167.08% */
      font-size: 36.094px;
    }
  }

  & p{
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 15.047px;
    font-style: normal;
    font-weight: 300;
    line-height: 26.46px; /* 175.85% */
    letter-spacing: 0.9px;
    margin-bottom:40px
  }

  & img{
    max-width:100%;
    height:auto;
    margin:40px 0;

    @media ${THEME.TABLET} {
      /* height:450px; */
      aspect-ratio:16/9;
    }
  }
`

export const ServiceListHolder = styled.ul`
  padding:0 40px;
  /* text-indent:20px; */
  display:flex;
  flex-direction:column;
  gap:30px;
`

export const ServiceList = styled.li`
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 15.328px;
    font-style: normal;
    font-weight: 300;
    line-height: 26.46px; /* 172.624% */
    letter-spacing: 0.9px;
    list-style:none;
    position:relative;
    display:inline-block;
    width:max-content;

    &::after{
      content:'';
      position:absolute;
      width:20px;
      height: 1px;
      background:#000;
      top:50%;
      transform:translateX(-50%);
      left:-20px;
    }

    &::before{
      content:'';
      position:absolute;
      width:${props=>props.underline ? '100%' : 0};
      height: 1px;
      background:#000;
      bottom:0;
      left:0;
    } 
`