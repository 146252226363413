export const serviceData = [
    {
        key:'1',
        title:'Creative & Innovative',
        content:'Building innovative Software solutions to help Clients stay top in the business',
        order: 1,
    },
    {
        key:'2',
        title:"100% Client Satisfaction",
        content:'At every walk of the project, we ensure client satisfaction as our mantra',
        order: 0,
    },
    {
        key:'3',
        title:'Quality First',
        content:'Striving hard to offer best standard solutions to align with our clients’ goals and dreams',
        order: 1,
    },

]

export const factsData = [
    {
        key:'1',
        content:'We are Ideaux technologies started in 2022. We have skilled in various top technologies such as Java, Django, Reactjs, Php ',
        order: 1,
    },
    {
        key:'2',
        content:'Our end-to-end application development solutions are meticulously designed to consistently outdeliver your expectations. Our skilled team and advanced technology create bespoke software, ensuring seamless integration and enhanced user experience.',
        order: 0,
    },
    {
        key:'3',
        content:'Our team diligently strives to honour commitments and always comes out with flying colours.  All our team members take ownership of their goals and responsibility of their actions, even if it means taking responsibility of their shortcomings',
        order: 1,
    },
    {
        key:'4',
        content:'Developing rich and user-friendly iOS, Android, and Hybrid applications by leveraging native and cross-platform technologies.',
        order: 0,
    },
    {
        key:'5',
        content:'Creating tech driven solutions for both global brands and small businesses alike. We consider technology as a tool to create value for our clients.',
        order: 1,
    },
]