
import ServiceImg1 from '../../Assets/Images/Services/ServiceUI.webp'
import ServiceImg2 from '../../Assets/Images/Services/Service1.webp'
import Serviceimg3 from '../../Assets/Images/Services/ServiceMOBILE.webp'
import Serviceimg4 from '../../Assets/Images/Services/Service1Ecommerce.webp'

export const serviceList = [
    {
        key: '1',
        name: 'Research & Design'
    },
    {
        key: '2',
        name: 'Website & Apps'
    },
    {
        key: '3',
        name: 'E-Commerce Design'
    },
]

export const serviceData1 = [
    {
        key:'1',
        title:'UX/UI Design',
        content:'Create visually appealing unique websites that locks customer attention right from their first visit. Our designs are made following in-depth market research',
        img:ServiceImg1,
        list:[
            {
                key:'1',
                title:'Digital Strategy',
                under:false,
            },
            {
                key:'2',
                title:'UX Research + Design',
                under:true,
            },
            {
                key:'3',
                title:'UI Design / Webdesign',
                under:true,
            },
            {
                key:'4',
                title:'Interaction Design',
                under:false,
            },
        ]
    },
    {
        key:'2',
        title:'Custom Software Development',
        content:'Convert your business ideas into scalable software with our high quality and modern technology by leveraging our custom software development services, tailored to your business needs',
        img:ServiceImg2,
        list:[
            {
                key:'1',
                title:'WordPress Entwicklung',
                under:true,
            },
            {
                key:'2',
                title:'Craft CMS Entwicklung',
                under:true,
            },
            {
                key:'3',
                title:'Web Apps',
                under:true,
            },
            {
                key:'4',
                title:'Webflow Websites',
                under:true,
            },
            {
                key:'5',
                title:'Jamstack Websites',
                under:false,
            },
        ]
    }
]


export const serviceData2 = [
    {
        key:'1',
        title:'Mobile App Development',
        content:'Build a state-of-the-art mobile apps that meets ever-changing market demands and elevates customer experience',
        img:Serviceimg3,
        list:[
            {
                key:'1',
                title:'Shopify Shops',
                under:true,
            },
            {
                key:'2',
                title:'WooCommerce',
                under:true,
            },
            {
                key:'3',
                title:'Craft Commerce',
                under:true,
            },
            {
                key:'4',
                title:'Headless Commerce',
                under:false,
            },
        ]
    },
    {
        key:'2',
        title:'Ecommerce Development',
        content:'We create ecommerce sites that are more than just a virtual store. Give Your customers a first-class shopping experience and enhance your online presence with our ecommerce development services',
        img:Serviceimg4,
        list:[
            {
                key:'1',
                title:'WordPress Entwicklung',
                under:true,
            },
            {
                key:'2',
                title:'Craft CMS Entwicklung',
                under:true,
            },
            {
                key:'3',
                title:'Web Apps',
                under:true,
            },
            {
                key:'4',
                title:'Webflow Websites',
                under:true,
            },
            {
                key:'5',
                title:'Jamstack Websites',
                under:false,
            },
        ]
    }
]