import React, { useContext, useEffect, useState } from 'react'
import { ListCard, Controls } from './style'
import arrow from '../../../Assets/Images/Others/EnterArrow.svg'
import { CustomCursorContext } from '../../../Components/others/CustomCursor/context/CustomCursorContext'

export const Requirements = ({ data, update, selected, count, updatedata }) => {

    const [btnShow, setBtnShow] = useState(false)

    const { type, setType } = useContext(CustomCursorContext);
    useEffect(() => {
        if(selected.length != 0){
            setBtnShow(true)
        } else{
            setBtnShow(false)
        }
    }, [selected])
    

    const SelectedRequirt = () => {
        if (selected.length != 0) {
            count(1)
            updatedata();
        } 
    }

    return (
        <ListCard>
            <h1>Hey! 👋🏻 Tell us your requirements</h1>

            <div className='req'>
                {
                    data.map((item, index) => (
                        <span key={item.key} onMouseEnter={() => setType('button')}
                        onMouseLeave={() => setType('default')} onClick={() => update(item)} className={selected?.some((selectedItem) => selectedItem?.key === item?.key) ? 'active' : ''}>{item.name}</span>
                    ))
                }
            </div>

            {
                btnShow && (
                    <Controls>
                        <span className='btn' onClick={SelectedRequirt}>ok</span>
                        <div>Press<span className='text'>Enter</span><img src={arrow} title='Enter Arrow' alt="Enter Arrow" />to continue</div>
                    </Controls>
                )
            }
        </ListCard>
    )
}
