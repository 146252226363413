import React, { useState } from 'react'
import { NavBar } from '../../Components/NavBar/NavBar'
import { Footer } from '../Home/Partials/Footer'
import styled from 'styled-components'
import { Container } from '../../Components/Container'
import { THEME } from '../../Theme'
import { GradiantLetter } from '../../Components/others/GradiantLetter'

const Privacy = () => {
    const [navSticky, setNavSticky] = useState(false)


    const setSticky = () => {
        if (window.scrollY >= 200) {
            setNavSticky(true)
        } else {
            setNavSticky(false)
        }
    }

    window.addEventListener('scroll', setSticky)

    return (
        <div id={'main-container'}
            className="main-container"
            data-scroll-container>

            <NavBar sticky={navSticky} />

            <PrivacyWrapper>
                <Container>
                    <h1><GradiantLetter>Privacy Policy for IdeauxTech Pvt. Ltd.</GradiantLetter></h1>

                    <p className='content'>At IdeauxTech Pvt. Ltd., we are committed to protecting the privacy and security
                        of your personal information. This Privacy Policy outlines how we collect, use,
                        disclose, and protect the information collected through our website and services.</p>

                    <div class="container">
                        <h2>Information We Collect</h2>

                        <div class="wrapper">
                            <h4>Personal Information : </h4>

                            <p>When you visit our website or use our services, we may collect personal
                                information such as your name, email address, contact number, and
                                company name.</p>

                            <p>We may also collect payment information, including credit card details, for
                                billing purposes. However, we do not store payment card information on our
                                servers. Instead, we use trusted third-party payment processors to handle
                                payment transactions securely.</p>
                        </div>

                        <div class="wrapper">
                            <h4>Usage Data : </h4>

                            <p>We collect usage data, including log files, IP addresses, browser type, device
                                information, and timestamps, to analyze trends, administer the website, and
                                track user activity.</p>
                        </div>
                    </div>

                    <div class="container">
                        <h2>How We Use Your Information</h2>

                        <div class="wrapper">
                            <h4>Providing Services : </h4>

                            <p>We use the information collected to provide and improve our services,
                                including website functionality, customer support, and communication with
                                users.</p>
                        </div>

                        <div class="wrapper">
                            <h4>Communication : </h4>

                            <p>We may use your contact information to communicate with you about your
                                account, inquiries, updates, and promotional offers.</p>
                        </div>

                        <div class="wrapper">
                            <h4>Analytics : </h4>

                            <p>We use usage data to analyze website usage patterns, identify areas for
                                improvement, and optimize the user experience.</p>
                        </div>
                    </div>

                    <div class="container">
                        <h2>Information Sharing and Disclosure</h2>

                        <div class="wrapper">
                            <h4>Third-Party Service Providers : </h4>

                            <p>We may share personal information with trusted third-party service
                                providers who assist us in delivering our services, such as hosting providers,
                                analytics platforms, and customer support tools.</p>
                        </div>

                        <div class="wrapper">
                            <h4>Legal Compliance : </h4>

                            <p>We may disclose personal information when required to comply with
                                applicable laws, regulations, legal processes, or government requests.</p>
                        </div>

                        <div class="wrapper">
                            <h4>Business Transfers : </h4>

                            <p>In the event of a merger, acquisition, or sale of assets, personal information
                                may be transferred as part of the transaction. We will notify users of any
                                such change in ownership or control of their personal information.</p>
                        </div>
                    </div>

                    <div class="container">
                        <h2>Data Security</h2>

                        <div class="wrapper">
                            <h4>Security Measures : </h4>

                            <p>We implement industry-standard security measures to protect personal
                                information from unauthorized access, disclosure, alteration, or destruction.</p>

                            <p>These measures include encryption, access controls, firewalls, and regular
                                security audits.</p>
                        </div>

                        <div class="wrapper">
                            <h4>Data Retention : </h4>

                            <p>We retain personal information only for as long as necessary to fulfill the
                                purposes outlined in this Privacy Policy or as required by law. When
                                personal information is no longer needed, we securely dispose of it in
                                accordance with our data retention policies.</p>
                        </div>
                    </div>

                    <div class="container">
                        <h2>Your Privacy Choices</h2>

                        <div class="wrapper">
                            <h4>Opt-Out : </h4>

                            <p>You may opt out of receiving promotional communications from
                                IdeauxTech Pvt. Ltd. by following the unsubscribe instructions provided in
                                the communication or by contacting us directly.</p>
                        </div>
                    </div>

                    <div class="container">
                        <h2>Changes to this Privacy Policy</h2>

                        <div class="wrapper">
                            <h4>Updates : </h4>

                            <p>We may update this Privacy Policy from time to time to reflect changes in
                                our practices, legal requirements, or business operations.</p>

                            <p> We will notify users of any material changes to this Privacy Policy by
                                posting the updated version on our website or notifying users via email.</p>
                        </div>
                    </div>



                    <div class="container">
                        <h2>Contact Us</h2>

                        <div class="wrapper">

                            <p>If you have any questions, concerns, or feedback about this Privacy Policy or our
                                privacy practices, please contact us at <span>Emailid : </span> <a href="mailto:hr@ideaux.in" >hr@ideaux.in</a></p>
                        </div>
                    </div>

                   <AddressWrapper>
                    <h4>Address :</h4>

                    <div className='contentwrapper'>
                        <h6>309 ,Eden Brook ,</h6>
                        <h6>RajakamangalamRoad ,Ramanputhur ,</h6>
                        <h6>Nagercoil ,Kanyakumari Dist-629002 .</h6>
                    </div>
                   </AddressWrapper>
                </Container>
            </PrivacyWrapper>

            <Footer />
        </div>
    )
}

export default Privacy


const PrivacyWrapper = styled.div`
    padding:4rem 0;
    h1{
        color: #0362D7;
        font-family: 'Raleway', sans-serif;
        text-align:center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing:0.15em;
    }

    & .content{
        padding:2rem 0;
        color: #00000080;
        font-family: 'Raleway', sans-serif;
        text-align:justify;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-indent:50px;
    }

    & .container{
        padding:1rem 0;

        h2{
        color: #0362D7;
        font-family: 'Raleway', sans-serif;
        text-align:left;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom:1.5rem;
    }

    & .wrapper{
        padding:1rem 0 .5rem;

        h4{
        color: #000;
        font-family: 'Raleway', sans-serif;
        text-align:left;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        text-transform: capitalize;
        }

        p{
            color: #00000080;
            font-family: 'Raleway', sans-serif;
            text-align:justify;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            text-indent:50px;
            margin:1rem;

            span{
                color:#000;
                font-weight: 700;
                font-size:20px; 
            }
        }
    }
    }
`

const AddressWrapper = styled.div`
    padding:2rem 0;

    h4{
        color: #212529;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 31px;
        letter-spacing: 0.12px;
    }

    & .contentwrapper{
        flex:1;
        margin-top:20px;

        h6{    
            color: rgba(0, 0, 0, 0.30);
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;   
            line-height: 28px;
            letter-spacing: 0.175px;
        }
    }
`