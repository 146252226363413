import axios from 'axios'


const baseURLs = {
  development: 'http://192.168.29.106:8085/', // API server url (Development)
  production: 'https://dev.api.ideauxbill.in/', // API server url (Production)
  staging: 'https://dev.hibiller.com/',  // API server url (Staging)
};

const environment = process.env.NODE_ENV || 'development'
// const environment = 'production'

export const request = axios.create({
  baseURL: baseURLs[environment],
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export default request


