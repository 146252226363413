import styled from "styled-components"
import { THEME } from "../../../Theme"


export const AimHolder = styled.div`
  margin: 20px 0;
`

export const AimCardHolder = styled.div`
    width:100%;
    padding:30px;
    margin-top:20px;
    display:flex;
    position:relative;
    justify-content:center;
    opacity:0;
    
    @media ${THEME.MOBILEL} {
        justify-content:${props=>props.order === 1 ? 'flex-start' : 'flex-end' };
    }
`

export const AimCardRow = styled.div`
    width:285px;
    height:100%;
    display:flex;
    align-items:flex-start;
    flex-direction:column;
    gap:22px;
`
export const AimTilte =styled.h2`
  color:#fff;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
`
export const AimContent =styled.h2`
  color: #FAFAFA;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; 
  letter-spacing: 0.56px;
`
export const FactCardRow = styled.div`
    width:600px;
    height:100%;
    display:flex;
    align-items:flex-start;
    flex-direction:column;
    gap:22px;
`

export const FactContent= styled.h2`
font-family: 'Poppins', sans-serif;
  color:#fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; 
  letter-spacing: 0.56px;

`

export const OneStartUp = styled.div`
margin-top: 5%;

@media (max-width: 987px) {
margin-top: -40px;
}


@media (max-width: 768px) {
margin-top: -60px;
}

& h3 {
color: #000;
font-family: 'Raleway', sans-serif;
font-size: 64px;
font-style: normal;
font-weight: 700;
line-height: 80px; /* 125% */
letter-spacing: 2.24px;

@media (max-width: 768px) {
font-size: 44px;
line-height: 60px; /* 125% */
}
@media (max-width: 580px) {
font-size: 28px;
line-height: 40px;
}



}

& span {
color: #0362D7;
font-family: 'Raleway', sans-serif;
font-size: 64px;
font-style: normal;
font-weight: 700;
line-height: 80px;
letter-spacing: 2.24px;

@media (max-width: 768px) {
font-size: 44px;
line-height: 60px;
}

@media (max-width: 580px) {
font-size: 28px;
line-height: 40px;
}
}
`
export const StartUpContent = styled.div`
/* display: flex;
justify-content: center;
align-items: center; */
color: #696969;
font-family: 'Inter', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; 
letter-spacing: 0.56px;

/* @media (max-width: 1269px) {
  display: none;
} */

@media (max-width: 768px) {
  display: block;
}

`
export const Name = styled.h2`
margin-top: 15px;
display: flex;
justify-content: center;
color: #000;
font-family: 'Raleway', sans-serif;
font-size: 32px;
font-style: normal;
font-weight: 600;
/* line-height: 30px; */
text-align:center;
letter-spacing: 1.12px;

@media (max-width: 768px) {
 margin-top: 10px;
}
`

export const Black = styled.div`
position: relative;
margin-top: 30px;
height:100%;
background: #101010;
padding:20px;
overflow:hidden;

& span.sticky{
  position:fixed;
    /* right: 0 !important;
    top: 20px !important; */
  right:-50%;
  bottom:20%;
  transform:rotate(90deg);
  color: #616161;
  text-align: right;
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 166.667% */
  letter-spacing: 1px;
  text-transform: capitalize;
  transition:0.5s;

  &.active{
    /* bottom:10%; */
    right:0%;
  }
}
/* height: 1750px; */

@media (max-width: 768px) {
/* height: 1650px; */
}
`

export const Aim = styled.h3`
margin-top: 120px;
color: #FFF;
font-family: 'Raleway', sans-serif;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 80px; 
letter-spacing: 1.68px;
`
export const RightCurve = styled.div`

& p {
/* margin-top: 120px; */
position: absolute;
bottom: 50px;
color: #FFF;
font-family: 'Inter', sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 35px; /* 145.833% */
}



`
export const Left = styled.div`


& p {
    display: flex;
justify-content: center;
align-items: center;
margin-top: -400px;
color: #FFF;
font-family: 'Inter', sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 35px; /* 145.833% */
@media (max-width: 768px) {
 margin-top: 5px;
}

}


`

export const Left1 = styled.div`

& p {
    display: flex;
justify-content: center;
align-items: center;
margin-top: 120px;
color: #FFF;
font-family: 'Inter', sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 35px; /* 145.833% */
@media (max-width: 768px) {
 margin-top: 20px;
}

}

`

export const Left2 = styled.div`

& p {
    display: flex;
justify-content: center;
align-items: center;
color: #FFF;
font-family: 'Inter', sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 35px; /* 145.833% */
@media (max-width: 768px) {
 margin-top: 20px;
}

}

`
export const Pdef = styled.div`
 display: flex;
justify-content: center;
align-items: center;
color: #FAFAFA;
font-family: 'Inter', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
letter-spacing: 0.56px;
`
export const Facts = styled.h2`
margin-top: 100px;
color: #FFF;
font-family: 'Raleway', sans-serif;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 80px; 
letter-spacing: 1.68px;

@media (max-width: 768px) {
 margin-top: 24px;
 font-size: 20px;


}


`
export const Spanone = styled.span`
color: #0362D7;
font-family: 'Raleway', sans-serif;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 80px;
letter-spacing: 1.68px;

@media (max-width: 768px) {
 margin-top: 24px;
 font-size: 20px;



}

`
export const AmazeFacts = styled.p`
/* margin-top: 10px; */
color: #FFF;
    font-family: 'Poppins', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; 
letter-spacing: 0.56px;
/* padding: 10px; */
margin: 60px;


@media (max-width: 768px) {
 margin-top: 5px;



}

`

export const CeoImg = styled.div`
display: flex;
justify-content: center;
align-items: center;
width:80%;
margin:auto;

& img {
    /* max-width: 400px; */
    max-width: 100%;
}
`

export const CurveImg = styled.div`



& img {
  @media (max-width: 768px)  {
display: none;

}

  @media (max-width: 1000px)  {
display: none;

}
}

`

export const SvgHolder = styled.div`
  position:absolute;
  left:55%;
  top:18%;
  transform:translateX(-50%);
  display:none;

  @media ${THEME.MOBILEL} {
    display:block;
  }

  &::before{
    width:8px;
    height: 8px;
    background:#fff;
    border-radius:8px;
    z-index:5;
    position:absolute;
    content:'';
    right:-3px;
    top:0;
  }
  &::after{
    width:8px;
    height: 8px;
    background:#fff;
    border-radius:8px;
    z-index:5;
    position:absolute;
    content:'';
    right:23px;
    bottom:0;
  }


  & #rocket {
    position:absolute;
    top: -25%;
    right: -8%;
    transform:rotate(45deg)
  }
`

export const VideoHolder = styled.div`
    display:flex;
      align-items:center;
      justify-content:center;
      overflow:hidden;
      /* border: 2px solid ${THEME.primary}; */
      /* padding: 10px; */
      border-radius: 12px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      position:relative;
      width:100%;
      
  & video{
      width:100%;
      height:100%;
      vertical-align:middle;
      display:inline-block;
      object-fit:cover;
  }
`