import styled from "styled-components";
import { CustomCardView } from "../../../Components/CustomCardView";


export const Product = styled.span`
background: linear-gradient(100deg, #9E00FF -7.16%, #F00 106.38%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    font-family: 'Poppins', sans-serif;
font-size: 64px;
font-style: normal;
font-weight: 600;
line-height: 80px;
letter-spacing: 2.24px;

@media (max-width: 768px) {
font-size: 32px;
    margin-top: 5px;
  }
`

export const OneProduct = styled.h1`
    font-family: 'Poppins', sans-serif;
font-size: 64px;
font-style: normal;
font-weight: 600;
line-height: 80px; 

@media (max-width: 768px) {
    margin-top: -80px;
font-size: 32px;

  }
`

export const TwoProduct = styled.div`
margin-top: 40px;
& p{
color: #343434;
    font-family: 'Poppins', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
letter-spacing: 0.56px;
margin-bottom:30px;
}
`

export const ThirdPart = styled.div`
margin-top:150px;
/* margin-left: 110px; */
display: flex;
align-items: center;
justify-content: center;

  @media (max-width: 768px) {
      margin-top:20px;
    }
`;

export const ValuableProd = styled.h1`
color: #000;
font-family: 'Raleway', sans-serif;
text-align:center;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 80px; /* 166.667% */
letter-spacing: 1.68px;
margin-bottom:50px;

@media (max-width: 768px) {
font-size: 22px;
  }

& span {
    background: linear-gradient(94deg, #9E00FF 39.33%, #FAFF00 121.02%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: 'Raleway', sans-serif;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 80px;
letter-spacing: 1.68px;

@media (max-width: 768px) {
font-size: 22px;
  }
}

`

export const Schl = styled.div`
background-color: black;
height: 500px;
display: flex;
align-items: center;
justify-content: center;

& img {
    max-width:50%;
    @media (max-width: 768px) {
        max-width:100%;

  }
}
`

export const Cards = styled(CustomCardView)`
  .schlhead{
  color: white;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  }
`

//   Products Card

export const ProductWrapper =styled.section`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    @media screen and (max-width:670px)  {
      height: 70vh;
      /* padding:2rem 0; */
    }

  & div.holder{
    width: 90%;
    margin: 0 auto;
  }
`

export const ProductCardWrapper = styled.div`
  display: flex;
  max-width:600px;
  height: max-content;
  flex-direction: column;
  /* min-height: 50vh; */
  aspect-ratio:16/9;
  align-items: center;
  position: relative;
  margin: auto;
    border-radius: 20px;
    background: #131313;
    box-shadow: 4px 4px 10px 0px rgba(57, 160, 240, 0.30);

    @media screen and (max-width:670px)  {
      aspect-ratio:16/12;
    }

@media screen and (max-width:540px)  {
  aspect-ratio:16/14;
}
`

export const ProductCard = styled.div`
    display: flex;
    width: 100%;
    height: max-content;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    padding: 0 68px; 
    flex-direction:column;
    height:100%;
    justify-content: space-evenly;

    @media screen and (max-width:670px)  {
    padding: 0 34px; 
    }

    @media screen and (max-width:540px)  {
        padding: 0 10px; 
    }

    & .title{
        display: flex;
        font-size: 42px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: left;
        align-items: flex-end;
        color: #fff;
        width: 100%;
        justify-content:center;
        overflow: hidden;
        /* background:red; */
        gap:20px;
        margin-top: 3vh;
    @media screen and (max-width:540px)  {
        margin-top: 10px;
    }

      & h1{
        /* background:green; */
        color: #FFF;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 45px; /* 140.625% */
        display: flex;
        flex-wrap: wrap;
        align-items:center;
        justify-content: center;
        gap: 10px;
        transform: translate(0px, 100%);
        transition: 0.5s linear;
        transition-delay: 0.3s;
    @media screen and (max-width:670px)  {
        font-size: 24px;
    }
    @media screen and (max-width:540px)  {
        font-size: 18px;
    }

        &.active{
              transform: translate(0px, 0px);
              transition-delay:1s;
              z-index:10;
        }
      }
    }

    & .product__content_container{
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          height: max-content;
          gap:10px;
    @media screen and (max-width:540px)  {
      gap:0;
      column-gap:3px;
    }

      .content{
            display: flex;
            width: max-content;
            height: max-content;
            overflow: hidden;
      }
    }
`

export const ProductContentDescriptionSpan = styled.span`
        color: #FFF;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 25px; /* 156.25% */
        letter-spacing: 0.56px;
        transition: 0.5s ease-in-out;
        transition-delay: 0.5s;
        transform: translate(0px, 100%);
    @media screen and (max-width:540px)  {
        font-size: 14px;
    }
        
        &.active{
              transform: translate(0px, 0px);
              transition-delay:1.2s;
              z-index:10;
        }
`