import React from "react";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "../Home/Partials/HomePage";
import Products from "../Products/Partials/Products";
import Startups from "../About/Partials/Startups";
import { LetsTalk } from "../LetsTalk/Partials/LetsTalk";
import ServiceNew from "../Services/Partials/ServiceNew";
import PageNotFound from "../PageNotFound/PageNotFound";
import Privacy from "../PrivacyPolicy/Privacy";


const AppRouter = () => {

	return (
		<Routes>
			<Route path="*" element={<HomePage />} />
			<Route path="/" element={<HomePage />} />
			<Route path="/aboutus" element={<Startups />} />  
			<Route path="/letstalk" element={<LetsTalk />} />
			{/* <Route path="/products" element={<Products />} /> */}
			<Route path="/service" element={<ServiceNew />} />
			<Route path="/privacypolicy" element={<Privacy />} />
		</Routes>
	);
}

export default AppRouter;
