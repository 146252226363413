import React from 'react'
import { ClientLogoWrapper, ClientsLogoHolder, MovingCardHolder, WorkWithSection, WorkedTilte } from './Style'
import { GradiantLetter } from '../../../Components/others/GradiantLetter'
import { ParallaxCard } from '../../../Components/others/ParallaxCard'
import { logo, logo1 } from '../../CustomData/ClientLogo'
import { Container } from '../../../Components/Container'

export const WorkedWith = () => {
    return (
       <section data-scroll-section>
        <Container >
         <WorkWithSection>
            <WorkedTilte>
                <GradiantLetter>We worked with</GradiantLetter>
            </WorkedTilte>

            <MovingCardHolder>
                <ParallaxCard baseVelocity={-10}>
                    <ClientLogoWrapper>
                        {
                            logo.map((item) => (
                                <ClientsLogoHolder key={item.key}>
                                    <img src={item.icon} title={item?.title}  alt={item?.title} />
                                </ClientsLogoHolder>
                            ))
                        }
                    </ClientLogoWrapper>
                </ParallaxCard>


                <ParallaxCard baseVelocity={10}>
                    <ClientLogoWrapper>
                        {
                            logo1.map((item) => (
                                <ClientsLogoHolder key={item.key}>
                                    <img src={item.icon} title={item?.title}  alt={item?.title}  />
                                </ClientsLogoHolder>
                            ))
                        }
                    </ClientLogoWrapper>
                </ParallaxCard>
            </MovingCardHolder>
        </WorkWithSection>
       </Container>
       </section>
    )
}
