import React from 'react'
import styled, { keyframes } from 'styled-components'

export const GradiantLetter = ({ children, size,weight }) => {

    return (
        <Title size={size} weight={weight}>{children}</Title>
    )
}

const gradientBack = keyframes`
    0% { filter: hue-rotate(0deg); }
    100% { filter: hue-rotate(360deg); }
`;

const Title = styled.span`
    background: linear-gradient(94deg, #9E00FF 39.33%, #FAFF00 121.02%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Raleway', sans-serif;
    animation: ${gradientBack} 3s ease-in-out infinite; /* Adjust the duration and timing function as needed */
`;
