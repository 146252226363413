import styled from "styled-components";
import { THEME } from "../../../Theme";

export const MainCursor = styled.div`
    height:10px;
    width:10px;
    border-radius:50%;
    position:fixed;
    top:0;
    left:0;
    pointer-events:none;
    background:${THEME.black};
    overflow:hidden;
    /* transform:translate3d(0,0,0); */
    transform:translate(-50%,-50%);
    z-index:${THEME.z_110};
    /* mix-blend-mode:difference; */
    display:none;
    /* transition:0.1s ease; */
    
    @media ${THEME.TABLET}{
        display:block;
    }
    
    &.hamburger{
        transition:.2s ease;
        background: ${THEME.black};
        height:80px;
        width:80px;
        z-index:10;
    }

    &.hamburger-sec{
        transition:.2s ease;
        background: ${THEME.black};
        height:80px;
        width:80px;
        z-index:100;
    }

    &.button{
       display:none;
    }
    &.link{
        background: #000000;
        mix-blend-mode:normal;
        height:150px;
        width:150px;
        /* backdrop-filter:blur(20px); */
        box-shadow:0px 0px 20px #000,
        0px 0px 40px #000,
        0px 0px 60px #000,
        0px 0px 80px #000,
        0px 0px 100px #000,
        0px 0px 120px #000,
        0px 0px 140px #000;
    }
`