import React, { Fragment } from 'react'
import { Input as AntdInput, Form } from 'antd'
import styled from 'styled-components'

import Label from './Label'
import { THEME } from '../Theme'

const { Item } = Form

const StyledItem = styled(Item)`
  > div {
    width: 100%;
    text-align: left;
  }

  border-radius: 0.4rem;
  margin-bottom: 5px !important;

  & .ant-form-item-label {
    display:block;
    width:100%;
    text-align:start;
  }

  & .ant-form-item-label > label > span {
    font-weight: 600 !important;
    position: relative;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.03em;
   

  }
`
const AntdInputStyle = styled(AntdInput)`
  height: ${props => (props.height ? props.height : '40px')};
  /* border-radius: 0.4rem; */
  box-shadow: none;
  /* border-color: ${props => (props.error ? 'red' : '#d9d9d9')}; */
  padding: 0px 2px 10px 2px;
  border:none;
  border-radius:0;
  outline:none;
  border-bottom: 2px solid rgba(0,0,0,.3);
  
  ::placeholder {
        color: #A1A1A1;
        font-weight: 400;
  }
  &:hover{
    border-color:rgba(0, 0, 0, 0.76);
  }
  &:focus {
    border-color:rgba(0, 0, 0, 0.76);
    box-shadow:none;
  }

  &.ant-input-status-error:not{
    outline:none;
    box-shadow:none;
  }
  &.ant-input-status-error{
    border-color:rgba(0, 0, 0, 0.76);
    outline:none;
    box-shadow:none;
  }
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus-within {
    border-color:rgba(0, 0, 0, 0.76);
    outline:none;
    box-shadow:none;
  }
&.ant-input:focus-within{
    border-color:rgba(0, 0, 0, 0.76);
    outline:none;
    box-shadow:none;
}
  :hover {
    border:1px solid #b3d8ff;
  }

  :not(.ant-input-affix-wrapper-disabled):hover {
    border:1px solid #b3d8ff !important;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-right-width: 0px !important;
  }

  &.ant-input{
    background: #FFF;
    font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.16px;
        color: #000;

        @media ${THEME.TABLET} {
        font-size: 20px;
    }
  }

  &.ant-input[disabled] {
    color: #545454;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    border: 1px solid #d9d9d9;
  }
`
export const CustomInput = ({
    label,
    type,
    name,
    rules,
    step,
    onChange,
    placeholder,
    display,
    required,
    autoFocus,
    disabled,
    readOnly,
    width,
    height,
    marginRight,
    labelStyle,
    defaultValue,
    minWidth,
    value,
    optional,
    initialValue,
    noStyle = undefined,
    ...rest
}) => {

    return (
        <StyledItem
            style={{
                width: width,
                marginRight: marginRight,
                minWidth: minWidth,
                display: display,
            }}
            rules={rules}
            noStyle={noStyle}
            name={name}
            colon={false}
            required={false}
            initialValue={initialValue}
            label={
                label && (
                    <Fragment>
                        <Label required={required} labelStyle={labelStyle}>
                            {label} <span>{optional}</span>
                        </Label>
                    </Fragment>
                )
            }
        >
            <AntdInputStyle
                {...rest}
                defaultValue={defaultValue}
                type={type}
                autoFocus={autoFocus}
                readOnly={readOnly}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                height={height}
                step={step}
            />
        </StyledItem>
    )
}
