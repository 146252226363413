import React, { useContext, useState } from 'react'
import {
    SecondaryNavHolder,
    SecLogo,
    SecMenuItemsWrapper,
    NavToggleBtn,
    MenuLink,
} from './styled'
import seclogo from '../../Assets/Images/companyDetails/ideaux-logo.webp'
import { secNavItems } from './Data'
import { useLocation } from 'react-router-dom'
import { CustomCursorContext } from '../others/CustomCursor/context/CustomCursorContext'

export const SecondaryNav = ({ sticky, activeBtn, updateactive }) => {


    const { type, setType } = useContext(CustomCursorContext);
    const location = useLocation();

    return (
        <SecondaryNavHolder className={`${sticky ? 'sticky' : ''}`} id='nav-header'>
            <SecLogo href='/'>
                <img src={seclogo} alt="IdeauxTech Pvt Ltd Logo" title='IdeauxTech Pvt Ltd logo' />
            </SecLogo>

            <SecMenuItemsWrapper>
                {
                    secNavItems?.map((item, index) => (
                        // <MenuLink to={item.path} key={index}>
                        <a href={item.path} key={index}
                            onMouseEnter={() => setType('hamburger-sec')}
                            onMouseLeave={() => setType('default')} className={location.pathname === item.path ? 'active' : ''} ><span data-title={item.name}>{item.name}</span></a>
                        // </MenuLink>
                    ))
                }

            </SecMenuItemsWrapper>

            <NavToggleBtn onClick={() => updateactive(!activeBtn)}
                onMouseEnter={() => setType('hamburger-sec')}
                onMouseLeave={() => setType('default')}>
                <span className={activeBtn ? 'active' : ''}></span>
                <span className={activeBtn ? 'active' : ''}></span>
            </NavToggleBtn>

        </SecondaryNavHolder>
    )
}
