import React from 'react'
import {
    ListWrapper,
    NavMenu,
    TalkWrapper,
    TalkHolder,
    TopNav,
    NavCloseBtn,
    BottomNav,
    BottomNavWrapper,
    BottomNavHolder,
    BottomLinkHolder,
} from './styled'
import { GradiantLetter } from '../others/GradiantLetter'
import whitelogo from '../../Assets/Images/companyDetails/whitelogo.webp'
import { mainMenu, socialLinks, subMenuItems } from './Data'


export const SubMenu = ({ activeBtn, updateactive }) => {
    return (
        <NavMenu className={activeBtn ? 'active' : ''}>
            <TalkWrapper>
                <TalkHolder>
                    <h2>Have an Idea?</h2>
                    <a href='/letstalk'>
                        <GradiantLetter size={'48px'}>Let’s Talk about it</GradiantLetter>
                    </a>
                </TalkHolder>
                <h5>India</h5>
            </TalkWrapper>
            <ListWrapper>
                <TopNav>
                    <img src={whitelogo} alt="IdeauxTech Pvt Ltd Logo" title='IdeauxTech Pvt Ltd logo' />

                    <NavCloseBtn onClick={() => updateactive(!activeBtn)}>
                        <span className={activeBtn ? 'active' : ''}></span>
                        <span className={activeBtn ? 'active' : ''}></span>
                    </NavCloseBtn>
                </TopNav>

                <BottomNav>
                    {/* <Container style={{height:'100%',margin:'auto'}}> */}
                    <div>
                        <BottomNavWrapper>
                            <BottomNavHolder>
                                <div className='hamburger_nav_main_links_container'>
                                    {
                                        mainMenu.map((item, index) => (
                                            // <Link to={item.path}>
                                                <a href={item.path} className='links' onClick={() => updateactive(!activeBtn)} key={index}>{item.name}</a>
                                            // </Link>
                                        ))
                                    }
                                </div>
                                <div className='hamburger_nav_side_links_container'>
                                    {
                                        subMenuItems.map((item, index) => (
                                            // <Link to={item.path} key={index}>
                                                <a href={item.path} className='links' key={index} onClick={() => updateactive(!activeBtn)}>{item.name}</a>
                                            // </Link>
                                        ))
                                    }
                                </div>
                            </BottomNavHolder>
                            <BottomLinkHolder>
                                {
                                    socialLinks.map((item, index) => (
                                        <a href={item.link} target='_blank' key={index}><span className='icon'>{item.icon}</span> <span className='name'>{item.name}</span></a>
                                    ))
                                }
                            </BottomLinkHolder>
                        </BottomNavWrapper>
                    </div>
                    {/* </Container> */}
                </BottomNav>
            </ListWrapper>
        </NavMenu>
    )
}
