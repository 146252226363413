import { useEffect, useState } from 'react';
import { ContainerFluid } from './Assets/CommonStyled';
import PreLoader from './Components/PreLoader';
import { StyledCursor } from './Components/others/CustomCursor/CustomCursor';
import { CustomCursorManger } from './Components/others/CustomCursor/context/manager';
import AppRouter from './Modules/AppRouter/AppRouter';
import "swiper/css";


const App = () => {

  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
   const loading = ()=>{
    setTimeout(()=>{
        setIsLoading(false)
    },500);
   }

   loading();
  }, [])
  

  return (
    <CustomCursorManger>
      <ContainerFluid>
        <StyledCursor/>
        {
          isLoading 
          ? (<PreLoader/>)
          : (<AppRouter />) 
        }
        
      </ContainerFluid>
      </CustomCursorManger>
  );
}

export default App;
