import SmileitLogo from '../../Assets/Images/Clients/smileitlogo.webp'
import InsvityLogo from '../../Assets/Images/Clients/insvitylogo.webp'
import ZayitLogo from '../../Assets/Images/Clients/zayitlogo.webp'

import RedLogo from '../../Assets/Images/Clients/brand.webp'
import PugalLogo from '../../Assets/Images/Clients/Pugal Events.webp'
import AariLogo from '../../Assets/Images/Clients/Aari World.webp'
import ShipLogo from '../../Assets/Images/Clients/ship.webp'
import GreenLogo from '../../Assets/Images/Clients/greennet.webp'
import AquaLogo from '../../Assets/Images/Clients/aquacare.webp'
import BenfieldLogo from '../../Assets/Images/Clients/benfield.webp'
import VijayLogo from '../../Assets/Images/Clients/vijay.jpeg'
import NurseryLogo from '../../Assets/Images/Clients/nursery.webp'
import KkrLogo from '../../Assets/Images/Clients/kkr.webp'
import BsfLogo from '../../Assets/Images/Clients/bsf.webp'



export const logo = [
    {
        key: '1',
        icon: SmileitLogo,
        title:'SmileitLogo',
    },
    {
        key: '2',
        icon: PugalLogo,
        title:'PugalEventManagementLogo',
    },
    {
        key: '3',
        icon: AariLogo,
        title:'AariWorldLogo',
    },

    {
        key: '4',
        icon: ShipLogo,
        title:'AjdinShipManagementLogo',
    },
    {
        key: '5',
        icon: RedLogo,
        title:'BrandPartnersLogo',
    },
    {
        key: '6',
        icon: ZayitLogo,       
        title:'ZayitLogo',
    },
    {
        key: '7',
        icon: GreenLogo,       
        title:'GreennetLogo',
    },
]


export const logo1 = [
    {
        key: '1',
        icon: InsvityLogo,
        title:'InsvityLogo',
    },
    {
        key: '3',
        icon: AquaLogo,
        title:'AquaCareLogo',
    },
    {
        key: '4',
        icon: BenfieldLogo,
        title:'BenfieldLogo',
    },
    {
        key: '5',
        icon: VijayLogo,
        title:'VijayHardwareLogo',
    },
    {
        key: '6',
        icon:NurseryLogo,
        title:'NurseryLogo',
    },
    {
        key: '7',
        icon: KkrLogo,
        title:'KKRAcademeyLogo',
    },
    {
        key: '8',
        icon: BsfLogo,
        title:'BsfLogo',
    },
]




